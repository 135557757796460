<script setup lang="ts">
import { computed } from 'vue'
import { useIMask } from 'vue-imask'
import BaseButton from '/~/components/base/button/base-button.vue'
import { BatchOrderPagination } from '/~/composables/batch-order/core/BatchOrderPagination'

const emit = defineEmits<{
  (event: 'select-per-page', perPage: number): void
  (event: 'select-page', page: number): void
}>()

const props = defineProps<{
  pagination: BatchOrderPagination
}>()

const hasNextPage = computed(() => {
  return props.pagination.currentPage < props.pagination.totalPages
})

const hasPrevPage = computed(() => {
  return props.pagination.currentPage > 1
})

const { el } = useIMask({
  mask: '00',
})

const perPageOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
]

function onPerPageChanged(event) {
  const index = event.target.options.selectedIndex

  emit('select-per-page', perPageOptions[index].value)
}

function onInputPageChanged() {
  if (Number.parseInt(el.value.value) === props.pagination.currentPage) {
    return
  }

  if (el.value.value > 1) {
    if (el.value.value >= props.pagination.totalPages) {
      onPageChanged(props.pagination.totalPages)
    }
  } else {
    onPageChanged(1)
  }
}

function onNext() {
  if (hasNextPage.value) {
    onPageChanged(props.pagination.currentPage + 1)
  }
}

function onBack() {
  if (hasPrevPage.value) {
    onPageChanged(props.pagination.currentPage - 1)
  }
}

function onPageChanged(page: number) {
  emit('select-page', page)
}
</script>
<template>
  <div
    class="flex flex-col justify-between gap-4 font-bold sm:flex-row sm:items-center"
  >
    <div class="mr-auto whitespace-nowrap text-xl">Payment details</div>
    <div class="flex items-center justify-between gap-x-4 sm:gap-x-6">
      <div class="flex items-center gap-x-3">
        <base-button
          icon="heroicons/outline/chevron-left"
          :size="26"
          :padding="2"
          data-testid="on-back-button"
          :disabled="!hasPrevPage"
          alt="back"
          @click="onBack"
        />
        <input
          ref="el"
          :value="pagination.currentPage"
          class="h-8 w-8 rounded-sm border text-center font-bold text-eonx-neutral-800 focus:border-2 focus:border-primary disabled:bg-inherit"
          inputmode="numeric"
          data-testid="page-input"
          @blur="onInputPageChanged"
          @keypress.enter="onInputPageChanged"
        />
        <div class="whitespace-nowrap">of {{ pagination.totalPages }}</div>
        <base-button
          icon="heroicons/outline/chevron-right"
          :size="26"
          :padding="2"
          :disabled="!hasNextPage"
          alt="next"
          data-testid="on-next-button"
          @click="onNext"
        />
      </div>
      <div class="flex items-center gap-x-3">
        <div class="whitespace-nowrap">Per page</div>
        <select
          :value="pagination.itemsPerPage"
          class="form-select h-8 w-16 rounded-sm border p-0 pl-2 font-bold focus:border-primary focus:ring-primary"
          data-testid="select-per-page"
          @change="onPerPageChanged($event)"
        >
          <option
            v-for="(option, i) of perPageOptions"
            :key="i"
            :value="option.value"
            data-testid="select-per-page-option"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
