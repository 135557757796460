<script setup lang="ts">
import { BatchOrderTransaction } from '/~/composables/batch-order'
import PayeeTableItem from './payee-table-item.vue'

const headerTitles = [
  { name: 'Status', class: 'w-16 truncate flex-none' },
  { name: 'Payee', class: 'min-w-24 flex-1' },
  { name: 'Reference*', class: 'w-24 flex-none' },
  { name: 'Amount', class: 'w-24 flex-none' },
]

defineProps<{
  payees: BatchOrderTransaction[]
  loading: boolean
}>()
</script>
<template>
  <div class="overflow-scroll rounded-b border-x text-sm">
    <div class="flex bg-eonx-neutral-100 font-bold">
      <div
        v-for="(item, i) of headerTitles"
        :key="i"
        :class="item.class"
        class="border-b bg-eonx-neutral-100 px-2 py-2"
      >
        {{ item.name }}
      </div>
    </div>
    <template v-if="payees.length">
      <payee-table-item
        v-for="(payee, i) of payees"
        :key="i"
        :payee="payee"
        :loading="loading"
      />
    </template>
    <div
      v-else
      class="flex items-center justify-center border-b py-12 font-bold"
    >
      <span
        :class="{
          'animate-pulse rounded bg-eonx-neutral-100 text-transparent': loading,
        }"
      >
        There are no payees to display under this status
      </span>
    </div>
  </div>
</template>
